
$tranition: all .3s ease-in-out;
$tranition_06: all .5s ease-in-out;

$fz20: 20px;
$fz24: 24px;

$def-col: #373535;
$grey-l: #96989B;
$red-col: #ec3438;

$c-E6E6E7: #E6E6E7;


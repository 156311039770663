/*.point-arr.active {*/
    /*display: none!important;*/
    /*z-index: 99!important;*/
/*}*/
.point-arr.active:after,
.point-arr.active:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    /*background-color: #00cc78;*/
    border-radius: 100%;
    -webkit-animation: pulse 2s infinite ease-in-out;
    animation: pulse 2s infinite ease-in-out;
}
.point-arr.active:before {
    height: 26px;
    width: 26px;
    opacity: .7;
    content: '';
    position: absolute;
    left: -8px;
    bottom: 0;
    top: -8px;
    border: 5px solid #e73538;
    /*background-color: #e73538;*/
    border-radius: 100%;
    -webkit-animation: pulse 2s infinite ease-in-out;
    animation: pulse 2s infinite ease-in-out;
}
.point-arr.active:after {
    height: 34px;
    width: 34px;
    content: '';
    position: absolute;
    left: -12px;
    top: -12px;
    border: 5px solid #e73538;
    border-radius: 100%;
    -webkit-animation-delay: .6s;
    animation-delay: .6s;
}


@-webkit-keyframes pulse {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}

@keyframes pulse {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1.3);
        opacity: 0;
    }
}

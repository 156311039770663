// Created Vit.vn vit.vnvnvn@gmail.com 2019


@import "partials/variable";
@import "partials/mixin";
@import "partials/arr-pulse";
@import "partials/slick-theme";
@import "partials/slick";

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  //background: #FF0000;
}

body{
  font-family: 'Comfortaa', cursive;
  font-size: 16px;
  color: $def-col;
  //scroll-behavior: smooth;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.container{
    width: 100%;
    max-width: 1260px;
    margin: auto;
    padding: 15px;
}

.section-nav{
  position: fixed;
  width: 100%;
  transition: $tranition_06;
  z-index: 99 ;

  &.header-fixed{
    border-bottom: 1px solid #f0f1f1;
    background: #fff;
    z-index: 999;
   
    .header-nav{
      .head-slide {
        margin-left: 60px;
      }
      .logo{
        opacity: 0;
        img{
          width: 200px;
        }
      }
    }
  }
}



//header
.header-nav{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: $tranition_06;

  .logo{
    opacity: 0;
    img{
      width: 260px;
      transition: $tranition_06;
    }
  }

  .head-slogan{
    opacity: 0;
    position: absolute;
    right: 110px;
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0;
    &.show{
      opacity: 1;
    }
    
    .head-slide{
      width: 100%!important;
      max-width: 850px!important;
    }
    .slogan-ithem{

      p{
        font-size: 15px;
        font-weight: 300;
        color: #9d9d9c;
        text-align: right;
        &:last-of-type{
          color: $red-col;
        }
      }
    }
  }
  .nav-btn{
    opacity: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    .nav-lang{
      margin-left: 7px;
      width: 35px;
      height: 40px;
      font-size: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1px;
      color: gray;
      background: url(../img/romb_emps.png);
      background-size: 100%!important;
      background-position: center !important;
      background-repeat: no-repeat!important;
      filter: grayscale(1);

      &:hover{
        color: $red-col;
        filter: none;
      }
      &:focus{
        background: url(../img/romb_red.png);
        color: #ffffff;
        filter: none;
      }

      span{
        display: block;
        margin-top: 2px;
      }
    }

    .nav-bt{
      width: 35px;
      height: 20px;
      border: 1px solid rgba(128, 128, 128, 0.55);
      margin-left: 7px;
      align-items: center;
      display: flex;
      justify-content: center;
      line-height: 1;
      padding: 7px 10px;
      border-radius: 25px;
      span{
        margin-bottom: -3px;
        font-size: 18px;
        line-height: 1.4;
        font-weight: 300;
        color: #373535;
      }
      &:hover{
        span{
          color: $red-col;
        }
      }
      &:active{
        background-color: $red-col;
        border-color: $red-col;
        span{
          color: #fff;
        }
      }
    }

    .nav-ithem{
      font-size: $fz20;
      padding: 12px 20px;
      border: 1px solid $def-col;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: normal;
      transition: $tranition;

      p{
        margin-bottom: -3px;
        transition: $tranition;
      }

      &:hover{
        border: 1px solid $red-col;

        p{
          color: $red-col;
        }
      }
    }
    .nav-ithem + .nav-ithem{
      margin-left: 20px;
    }
    .nav-ithem:last-of-type{
      margin-left: 40px;
    }
  }

}


.anime-logo{
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .main__bg_01{
    width: 100%;
    height: 100vh;
    opacity: 0;
    background: url(../img/bg_main.png);
    background-size: cover!important;
    background-position: center!important;
    transition: $tranition_06;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 92;
  }

  .container{
    text-align: center;
    height: 100vh;
    position: relative;
    
    .main-text__wrapp{
      opacity: 0;
      margin-top: 165px;
      z-index: 99;
      position: relative;
      width: 100%;
      max-width: 630px;
      &.ru-wrapp{
        max-width: 650px;
      }

      p{
        font-size: 16px;
        line-height: 1.4;
        &.dark{
          color: #747070;
          text-align: left;
        }
        &.red{
          color: $red-col;
          text-align: right;
        }
      }
    }

    .logo-containt{
      width: 100%;
      max-width: 685px;
      height: 260px;
      margin: auto;
      position: fixed;
      left: 50%;
      width: 100%;
      top: 50%;
      transform: translate(-50%, -50%);

      

      .logo-ithem{
        position: absolute;
        opacity: 0;
        z-index: 10;
      }

      .al_01{
        opacity: 0;
        left: 85px;
        top: 10px;
        z-index: 9;
        transform: rotate(-7deg);
        transform-origin: 50% 6px;
        .ap{
          width: 13px;
          height: 125px;
          display: block;
          background: #373535;
          border-radius: 10px 10px 0 0;
        }
        .em-t{
          width: 20px;
          height: 20px;
          background: #fffcfc;
          position: absolute;
          left: 3px;
          top: 92%;
          transform: rotate(45deg);
          z-index: 10;
        }
        .em-b{
          position: absolute;
          left: 3px;
          top: 0px;
          transform: rotate(0deg);
          border: 13px solid transparent;
          border-bottom: 13px solid #373535;
          border-left: 13px solid #373535;
          opacity: 0;
        }
      }

      .l_01{
        top: 16px;
        left: -6px;
        width: 100px;

        transform: rotateZ(-45deg);
        transform-origin: 94% 10px;
        z-index: 5;
        opacity: 0;
        img{
          width: 100px;
        }
        .emp {
          position: absolute;
          width: 17px;
          height: 13px;
          background: #ffffff;
          top: 3px;
          transform: rotate(45deg);
          right: -9px;
        }
      }
      .r_01{
        top: 70px;
        width: 13px;
        left: 85px;
        z-index: 5;
        opacity: 0;
      }
      .red_01{
        top: 21px;
        width: 75px;
        left: 10px;
        z-index: 4;
        opacity: 0;
        transform: rotateZ(0);
        transform-origin: 95% 15px;
      }
      .red_hide{
        position: absolute;
        width: 98px;
        height: 105px;
        z-index: 4;
        left: 0;
        top: 0;
        background: #fff;
        opacity: 1;
      }


      .a_11{
        top: -200%;
        left: -3px;
      }
      .word-n{
        top: 10px;
        left: 150px;
      }
      .word-g{
        top: 12px;
        left: 277px;
      }
      .word-l {
        left: 401px;
        top: 11px;
      }
      .word-e{
        top: 11px;
        left: 500px;
      }
      .word-s{
        top: 9px;
        left: 600px;
      }
      .word-text{
        z-index: 9;
        bottom: 110px;
        left: 150px;
      }

      .logo-img{
        width: 677px;
        top: 4px;
        position: absolute;
        left: 0;
        opacity: 0;
      }


    }
  }
}

#slide-01{
  margin-top: 40px;
}

.our-bus{
  opacity: 0;

  &_grid{
    display: none;
    grid-gap: 25px;
    grid-template-columns: calc(25% - 19px) calc(25% - 19px) calc(25% - 19px) calc(25% - 19px);
    background-color: #fff;
    color: #444;
  }
  &_ithem{
    min-height: 170px;
    padding: 20px;
  }
  &_title{
    font-size: $fz24;
    line-height: 1.4;
    font-weight: 300;
  }
  &_desc{
    color: $grey-l;
    font-size: $fz24;
    line-height: 1.4;
    font-weight: 300;
  }
}

.ithem-01{
  background: #F0F1F1;
}
.ithem-02{
  background: #D2D3D5;
}
.ithem-03{
  background: #E6E6E7;
}
.ithem-04{
  background: #F0F1F1;
}
.ithem-05{
  background: #F0F1F1;
}
.ithem-06{
  background: #E6E6E7;
}
.ithem-07{
  background: #F0F1F1;
}
.ithem-08{
  background: #D2D3D5;
}
.ithem-09{
  background: #E6E6E7;
}
.ithem-10{
  background: #F0F1F1;
}
.ithem-11{
  background: #D2D3D5;
}
.ithem-12{
  background: #F0F1F1;
}



.content-wrapp{
  opacity: 0;
  overflow: hidden;
  width: 100%;
  max-width: 1450px;
  margin: auto;

  .slide-ithem{
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 500px;
    position: relative;

    .triger{
      position: absolute;
    }
    .triger-last{
      position: absolute;
    }
    .prof-photo-one{
      width: 470px;
    }
    .prof-photo-tvo{
      width: 470px;
    }
    .col-l{
      justify-content: flex-end;
      padding-right: 70px;
      padding-left: 20px;
      .col-text_wrap{
        text-align: right;
        margin-right: 25px;
      }
      .col-img_wrap{
        justify-content: flex-end;
      }
      .prof-photo-one{
        right: 0;
      }
      .prof-photo-tvo{
        right: 0;
      }
      .photo-qud{
        right: -20px;
      }
      .img_bg-s{
        right: 0;
      }
      .img-obl{
        right: 0;
      }
      .img_bg-h{
        right: 0;
      }
    }


    .col-m{
      width: 18px;
      height: 0;
      background: $c-E6E6E7;
      position: relative;

      .point-arr{
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        background: #D2D3D5;
        border: 1px solid #96989B;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 90;
        opacity: 0;

        &.active {
          display: none;
          z-index: 99!important;
          top: 250px;
          
        }
      }
    }


    .col-r{
      justify-content: flex-start;
      padding-right: 20px;
      padding-left: 70px;
      .col-text_wrap{
        text-align: left;
        margin-left: 25px;
      }
      .col-img_wrap{
        justify-content: flex-start;
      }
      .prof-photo-one{
        left: 0;
      }
      .prof-photo-tvo{
        left: 0;
      }
      .photo-qud{
        left: -20px;
      }
      .img_bg-s{
        left: 0;
      }
      .img_bg-h{
        left: 0;
      }
    }
    .col-set{
      width: calc(50% - 9px);
      display: flex;
      align-items: center;
      position: relative;
      transition: $tranition;

      &:hover{
        .slide-title{
          color: $red-col;
        }
      }

    }
  }
}

.col-img_wrap{
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 300px;
  transition: all 1.2s ease-in-out;

  .img_bg-s {
    width: 90%;
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    opacity: 0;
  }
  .img_bg-h {
    width: 90%;
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    opacity: 0;
    transition: $tranition;
  }

  .img-obl {
    width: 90%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0,-50%);
    opacity: 0;
    transition: all 1.2s ease-in-out;
  }

  &:hover{
    .img-obl{
      left: 25px;
      transition: all 1.2s ease-in-out;
    }
    .img_bg-h{
      opacity: 1;
    }
  }
  .prof-photo-one{
    position: absolute;
    z-index: 2;
    top: 0;
    opacity: 0;
    transition: $tranition;
    &.hiden{
      opacity: 0!important;
    }
  }
  .prof-photo-tvo{
    position: absolute;
    z-index: 2;
    top: 0;
    transition: $tranition;
    opacity: 0;
    &.show{
      opacity: 1!important;
    }
  }
}


.slide-title{
  opacity: 0;
  font-size: 32px;
  margin-bottom: 20px;
  line-height: 1.4;
  font-weight: 400;
  color: #373535;
  transition: $tranition;
}
.slide-desc{
  opacity: 0;
  font-size: 17px;
  line-height: 1.4;
  font-weight: 300;
  color: #373535;
}

.col-text_wrap{
  max-width: 570px;


}
.portfolio-info__wrapp{
  position: relative;

  .portfolio-one{
    transition: $tranition;
    position: absolute;
    top: 0;

    &.hiden{
      opacity: 0!important;
      visibility: hidden;
    }
  }
  .portfolio-tvo{
    display: none;
    //opacity: 0;
    //visibility: hidden;
    transition: $tranition;
    position: absolute;
    top: 0;

    &.show{
      visibility: visible;
      opacity: 1!important;
    }
  }
}


.profile-name{
  font-size: 32px;
  margin-bottom: 20px;
  line-height: 1.4;
  font-weight: 400;
  color: #373535;
  opacity: 0;
}
.profile-contact{
  font-size: 16px;
  line-height: 1.4;
  font-weight: 300;
  color: #373535;
  display: block;
  opacity: 0;
}
.profile-about{
  font-size: 17px;
  line-height: 1.2;
  font-weight: 300;
  color: #373535;
  margin-top: 15px;
  opacity: 0;
}



#slide-01{
  .col-m{
    width: 18px;
    height: 70vh;
    min-height: 500px;
    background: transparent;
    opacity: 0;
    .first-arr{
      width: 100%;
      display: block;
      height: 50%;
      background: $c-E6E6E7;
      top: 50%;
      position: absolute;
    }
  }
}

#slide-09, #slide-10{
  height: 650px;
  .col-text_wrap{
    height: 550px;
    max-width: 550px;
    width: 100%;
    a{
      transition: all .2s ease-in-out;
      &:hover{
        color: $red-col;
      }
    }
  }
  .col-img_wrap{
    height: 550px;
    img{
      filter: grayscale(1);
    }
  }
}




#footer{
  height: 130px;
  min-height: 130px;
  padding: 60px 0 0;

  .col-set{
    height: 130px;
  }
  .col-text_wrap{
    height: 60px;
  }
  .col-m{
    height: 130px!important;
    background: transparent;
    .point-arr-bot {
      content: '';
      width: 100%;
      display: block;
      height: 135px;
      background: #E6E6E7;
      top: -65px;
      position: absolute;
    }
  }
  .logo{
    width: 250px;
    img{
      width: 100%;
    }
  }
  .info-footer{
    .company{
      font-size: 16px;
      line-height: 1.2;
      font-weight: 300;
      color: $red-col;
    }
    .adress{
      font-size: 16px;
      line-height: 1.2;
      font-weight: 300;
      color: $def-col;
    }
  }

}



.soc-nav{
  display: flex;
  justify-content: center;
  //padding-bottom: 40px;
  padding-left: 50px;
  .ico-soc{
    img{
      width: 45px;
    }
    &:hover{
      filter: none;
    }
  }
  .ico-soc+ .ico-soc{
    margin-left: 20px;
  }

}




.img--slide{
  width: 90%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  transition: all .8s ease-in-out;
  opacity: 0;
}
.col-l .img--slide{
  right: 0;
}
.col-r .img--slide{
  left: 0;
}

.img__01{
  z-index: 1;
}
.img__02{
  z-index: 2;
}
.img__03{
  z-index: 3;
}
.img__04{
  z-index: 4;
}
.img__05{
  z-index: 5;
}
.img__06{
  z-index: 6;
}
.img__07{
  z-index: 7;
}
.img__08{
  z-index: 8;
}



@keyframes opacityLight {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideLeft_05 {
  from {
    left: 0;
  }

  to {
    left: -5px;
  }
}
@keyframes slideLeft_10 {
  from {
    left: 0;
  }

  to {
    left: -10px;
  }
}
@keyframes slideLeft_15 {
  from {
    left: 0;
  }

  to {
    left: 15px;
  }
}
@keyframes slideLeft_20 {
  from {
    left: 0;
  }

  to {
    left: 20px;
  }
}



#slide-01 .col-img_wrap {
  &.active{
    transition: all 1.5s ease-in-out;
    .img__03{
      left: -11px;
      animation-name: slideLeft_05;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 4s;
    }
    .img__04{
      animation-name: slideLeft_05;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 1.8s;
    }
    .img__05{
      animation-name: slideLeft_10;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 1.8s;
    }
    .img__06{
      animation-name: slideLeft_15;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 2.2s;
    }
    .img__07{
      animation-name: slideLeft_10;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 2.8s;
    }
    .img__08{
      animation-name: slideLeft_20;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 3.18s;
    }
  }
}



@keyframes s_01 {
  from {
    right: 10px;
    top: 45%;
  }

  to {
    right: 0;
    top: 51%;
  }
}
@keyframes s_02 {
  from {
    right: 0px;
    top: 40%;
  }

  to {
    right: 10px;
    top: 45%;
  }
}
@keyframes rotate {
  from {
    transform: translate(0, -50%) rotate(0);
  }

  to {
    transform: translate(0, -50%) rotate(180deg);
  }
}


#slide-02  .img__06{
  transform: translate(0, -50%);
  width: 19%;
  left: 35%;
  top: 47.4%;
}

#slide-02 .col-img_wrap {
  &.active{
    .img__03{
      animation-name: s_01;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 4s;
    }
    .img__04{
      animation-name: s_02;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 1.7s;
    }
    .img__05{
      animation-name: s_01;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 2s;
    }
    .img__06{
      animation-name: rotate;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 5s;
    }
  }
}


@keyframes boxAnim {
  from {
    left: -5px;
  }

  to {
    left: 0;
  }
}


#slide-03 .col-img_wrap {
  &.active{
    .img__03{
      animation-name: boxAnim;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 3s;
      -webkit-animation-delay: 1s; /* Chrome, Safari, Opera */
      animation-delay: 1s;
    }
    .img__04{
      animation-name: boxAnim;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 3.1s;
    }
    .img__05{
      animation-name: slideLeft_20;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 3s;
    }
    .img__06{
      animation-name: slideLeft_10;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 1.4s;
    }
  }
}



@keyframes rotateTable {
  from {
    transform: translate(0, -50%) rotate(0deg);
  }

  to {
    transform: translate(0, -50%) rotate(7deg);
  }
}
@keyframes opacityL {
  from {
   visibility: hidden;
  }
  25%{
    visibility: visible;
  }
  to{
    visibility: hidden;
  }

}

#slide-04 .col-img_wrap {
  &.active{

    .img__04{
      animation-name: opacityL;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 2s;
      z-index: 7;
    }
    .img__03{
      transition: all .7s ease-in-out;
    }
    &:hover{
      .img__03{
        animation-name: rotateTable;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 1.2s;
      }
    }
  }
}


@keyframes colAnim {
  from {
    left: 0;
  }
  to {
    left: 3px;
  }
}
@keyframes antAnim {
  from {
    left: 79px;
    top: 45%;
  }

  to {
    left: 95px;
    top: 40%;
  }
}

#slide-05 .col-img_wrap {
  &.active{
    &:hover{
      .img__03{
        animation-name: colAnim;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 1s;
      }
    }

    .img__04{
      animation-name: colAnim;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 3.1s;
    }
    .img__05{
      animation-name: antAnim;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 6s;
    }
  }
}




@keyframes col_06-1 {
  from {
    right: 0;
  }
  to {
    right: 10px;
  }
}
#slide-06 .col-img_wrap {
  &.active{
    .img__03{
      animation-name: col_06-1;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 4s;
    }
    .img__04{
      animation-name: col_06-1;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 3.1s;
    }
    .img__05{
      animation-name: col_06-1;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 6s;
    }
    .img__06{
      animation-name: col_06-1;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 2s;
    }
    .img__07{
      animation-name: col_06-1;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 2.5s;
    }
    .img__08{
      animation-name: col_06-1;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 3.6s;
    }
  }
}



@keyframes col_07-1 {
  from {
    left: 0;
  }
  to {
    left: 10px;
  }
}
@keyframes col_07-2 {
  from {
    left: 0;
  }
  to {
    left: -10px;
  }
}
@keyframes col_07-3 {
  from {
    left: 0;
  }
  to {
    left: 20px;
  }
}

@keyframes col_07-4 {
  from {
    left: 0;
  }
  to {
    left: 90px;
  }
}
#slide-07 .col-img_wrap {
  &.active{
    .img__03{
      animation-name: col_07-2;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 1s;
    }
    .img__06{
      animation-name: col_07-1;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 3s;
    }
    &:hover{
      .img__05{
        animation-name: col_07-4;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 8s;
        transition: all .6s ease-in-out;
      }
      .img__04{
        opacity: 0!important;
        animation-name: col_07-4;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 8s;
        transition: all .6s ease-in-out;
      }
      .img__07{
        animation-name: col_07-2;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 2.5s;
      }
      .img__08{
        animation-name: col_07-3;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 2s;
      }
    }
  }
}



@keyframes col_08-1 {
  from {
    right: 0;
  }
  to {
    right: -3px;
  }
}
#slide-08 .col-img_wrap {
  &.active{
    .img__04{
      animation-name: col_08-1;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: 1s;
    }
    .img__05{
      animation-name: col_08-1;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-duration: .7s;
    }
  }
}



.col-img_wrap {
  &.active{
    .anime{
      opacity: 0;
    }
    &:hover{
      .anime{
        opacity: 1;
      }
    }
  }
}



.soc-ico{
  background-size: cover!important;
  background-position: center!important;
  width: 60px;
  height: 60px;
  position: relative;
  .info{
    display: block;
    width: 200px;
    position: absolute;
    bottom: 75px;
    background: #fff;
    padding: 20px;
    border: 1px solid #ececec;
    z-index: 999;
    border-radius: 5px;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: all .4s ease-in-out;
    &:after {
      content: "";
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-top-color: #ececec;
      bottom: -20px;
      left: 22px;
      position: absolute;
    }
    .red{
      color: $red-col;
    }
  }
  &.news{
    background: url(../img/soc/news-n.svg);
    &:hover{
      background: url(../img/soc/news-h.svg);
      .info{
        opacity: 1;
        visibility: visible;
      }
    }

  }
  &.events{
    background: url(../img/soc/events-n.svg);
    &:hover{
      background: url(../img/soc/events-h.svg);
      .info{
        opacity: 1;
        visibility: visible;
        
      }
    }
  }
}

.col-l .portfolio-info__wrapp .btn,
.col-r .portfolio-info__wrapp .btn{
  margin-left: auto;
  display: none;
}

.portfolio-info__wrapp  .btn{
  margin-top: 35px;
  padding: 10px;
  border: 1px solid grey;
  width: 150px;
  text-align: center;
  border-radius: 20px;
  font-size: 18px;
}



@media (max-width: 1300px){
  .container {
    max-width: 988px;
  }
  .content-wrapp .slide-ithem .col-l {
    padding-right: 25px;
  }
  .content-wrapp .slide-ithem .col-r {
    padding-left: 25px;
  }
}


@media (max-width: 1150px){
  .profile-about {
    font-size: 15px;
  }
  .content-wrapp .slide-ithem .prof-photo-one {
    width: 420px;
    margin-top: 45px;
  }
  .content-wrapp .slide-ithem .prof-photo-tvo {
    width: 420px;
    margin-top: 45px;
  }
  .container {
    max-width: 910px;
  }
  .anime-logo .container .main-text__wrapp {
    margin-top: 140px;
    max-width: 445px;
  }


  .col-img_wrap.active .anime {
    opacity: 1;
  }
  #slide-04 .col-img_wrap {
    &.active{
      .img__03{
        animation-name: rotateTable;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 1.2s;
      }
    }
  }

  #slide-05 .col-img_wrap {
    &.active{
      .img__03 {
        animation-name: colAnim;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 1s;
      }
    }
  }
  #slide-07 .col-img_wrap {
    &.active{
      .img__05{
        animation-name: col_07-4;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 8s;
        transition: all .6s ease-in-out;
      }
      .img__04{
        opacity: 0!important;
        animation-name: col_07-4;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 8s;
        transition: all .6s ease-in-out;
      }
      .img__07{
        animation-name: col_07-2;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 2.5s;
      }
      .img__08{
        animation-name: col_07-3;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 2s;
      }
    }
  }
  .content-wrapp .slide-ithem {
    height: 385px;
  }
  .col-l .portfolio-info__wrapp .btn,
  .col-r .portfolio-info__wrapp .btn{
    display: block;
  }





}

@media (max-width: 991px){

  .section-nav.header-fixed .header-nav .logo img {
    width: 150px;
  }
  .header-nav .logo img {
    width: 200px;
  }
  .header-nav .head-slogan .slogan-ithem p {
    font-size: 13px;
  }
  .container {
    max-width: 715px;
  }
  .header-nav .head-slogan .head-slide {
    max-width: 435px !important;
  }
  .header-nav .head-slogan {
    right: 75px;
  }
  .content-wrapp .slide-ithem .col-m {
    display: none;
  }

  .content-wrapp .slide-ithem {
    flex-direction: column;
  }
  .content-wrapp{
    width: 700px;
    margin: auto;
  }
  .content-wrapp .slide-ithem .col-set {
    width: 100%;
  }
  .col-img_wrap {
    width: 700px;
    height: 360px;
  }
  .content-wrapp .slide-ithem .triger {
    position: absolute;
    top: 0;
  }
  .reverse{
    flex-direction: column-reverse!important;
  }

  .content-wrapp .slide-ithem {
    height: auto;
    margin-bottom: 40px;
  }
  .col-img_wrap.active .anime {
    opacity: 1!important;
  }
  .col-l .img--slide {
    height: 100%;
  }
  .col-r .img--slide {
    height: 100%;
  }
  .col-img_wrap {
    margin-top: 30px;
  }
  #slide-09, #slide-10 {
    height: auto;
  }
  #slide-09 .col-text_wrap, #slide-10 .col-text_wrap {
    height: auto;
    max-width: 550px;
    width: 100%;
  }
  .content-wrapp .slide-ithem .prof-photo-one {
    width: 420px;
    margin-top: 0;
  }
  .content-wrapp .slide-ithem .prof-photo-tvo {
    width: 420px;
    margin-top: 0;
  }
  .portfolio-one{
    position: relative!important;
  }
  .portfolio-tvo{
    display: none;
    position: relative!important;
  }
  .content-wrapp .slide-ithem .col-r .prof-photo-one {
    right: 0;
    left: inherit;
  }
  .content-wrapp .slide-ithem .col-r .prof-photo-tvo {
    right: 0;
    left: inherit;
  }
  .content-wrapp .slide-ithem .col-l .prof-photo-one {
    left: 0;
    right: inherit;
  }
  .content-wrapp .slide-ithem .col-l .prof-photo-tvo {
    left: 0;
    right: inherit;
  }

  #footer {
    height: auto;
    min-height: 130px;
    padding: 0;
  }
  #footer .col-set {
    height: auto;
    margin: auto;
    justify-content: center;
    padding: 0;
  }
  #footer .col-r {
    margin-top: 25px;
  }
  #footer .col-text_wrap {
    height: 60px;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .anime-logo {
    height: 650px;
  }
  .section-nav.header-fixed {
    height: 100px;
  }
  .section-nav.header-fixed .header-nav .head-slide {
    margin-left: 75px;
  }
  .head-slogan{
    display: block!important;
    position: absolute!important;
    top: 165%!important;
    opacity: 1;
    width: 100%!important;
  }
  .anime-logo .main__bg_01 {
    height: 650px;
    margin-top: 115px;
    background-position: 65%!important;
  }
  .anime-logo .container .main-text__wrapp p {
    font-size: 14px;
    line-height: 1.4;
  }
  .container {
    max-width: 520px;
  }
  //.head-slogan{
  //  display: none;
  //}
  .content-wrapp .slide-ithem .col-set {
    max-width: 510px;
    //margin: auto;
  }
  .content-wrapp .slide-ithem .col-l .col-text_wrap {
    margin-right: 0;
  }
  .content-wrapp .slide-ithem .col-r .col-text_wrap {
    margin-left: 0;
  }
  .content-wrapp {
    width: 100%;
    margin: auto;
    z-index: 99;
    position: relative;
    background: #fff;
  }
  .anime-logo .container .main-text__wrapp {
    margin-top: 90px;
    max-width: 395px;
  }

  .content-wrapp .slide-ithem .col-r {
    padding-left: 0;
    padding-right: 0;
  }
  .col-img_wrap {
    width: 100%;
    height: 300px;
  }
  .content-wrapp .slide-ithem {
    align-items: center;
  }
  .img--slide {
    width: 100%;
  }
  #slide-02 .img__06 {
    transform: translate(0, -50%);
    width: 21%;
    left: 28%;
    top: 48%;
  }
  .content-wrapp .slide-ithem .prof-photo-one {
    width: 100%;
    margin-top: 0;
  }
  .content-wrapp .slide-ithem .prof-photo-tvo {
    width: 100%;
    margin-top: 0;
  }

  .anime-logo .container .logo-containt {
    transform: translate(-50%, -50%) scale(.7);
    left: 50%;
    top: 35%;
    max-width: 680px;
    width: 1000px;
  }
  .section-nav.header-fixed .header-nav .logo img {
    width: 120px;
  }
  .container {
    max-width: calc(100% - 30px);
    width: 100%;
    padding: 5px 15px;
  }
  .header-nav .head-slogan {
    right: 75px;
  }
  .header-nav .head-slogan .head-slide {
    max-width: 100% !important;
  }
  #footer .col-r {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
  }
  .soc-nav {
    padding-left: 0;
    margin-top: 30px;
  }
  
}
@media (max-width: 550px) {

  .anime-logo .container .logo-containt {
    transform: translate(-50%, -50%) scale(.4);
    left: 50%;
    top: 35%;
    max-width: 680px;
    width: 1000px;
  }
  .header-nav .logo img {
    width: 135px;
  }
  .container {
    max-width: calc(100% - 30px);
    width: 100%;
    padding: 5px 15px;
  }
  .content-wrapp .slide-ithem .col-set {
    width: calc(100% - 30px);
    padding: 0 15px;
  }
  .section-nav.header-fixed .header-nav .logo img {
    width: 120px;
  }
  .slide-title {
    font-size: 26px;
  }
  .slide-desc {
    font-size: 15px;
  }
  .profile-name {
    font-size: 26px;
  }
  .profile-contact {
    font-size: 16px;
  }
  .col-img_wrap {
    height: 270px;
    margin-left: -20px;
    margin-right: -20px;
  }
  #slide-02 .img__06 {
    transform: translate(0, -50%);
    width: 23.5%;
    left: 17.6%;
    top: 47%;
  }
  .img--slide {
    width: 115%;
  }

}

@media (max-width: 450px) {
  .col-img_wrap {
    height: 200px;
  }
  #slide-09 .col-img_wrap, #slide-10 .col-img_wrap {
    height: 475px;
  }
  .header-nav .nav-btn .nav-lang {
    margin-left: 7px;
    width: 29px;
  }
}
@media (max-width: 375px) {
  #slide-09 .col-img_wrap, #slide-10 .col-img_wrap {
    height: 385px;
  }
}
